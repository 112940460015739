import React from "react";
import SvatbyEl from "../components/svatbyEl";

const Svatby = () => {
  return (
    <SvatbyEl />
  )
};

export default Svatby;
